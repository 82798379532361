<template>
  <v-navigation-drawer
    :model-value="drawer"
    @update:model-value="updateDrawer"
    app
    v-if="shouldDisplayDrawer"
  >
    <template v-if="isAdminView">
      <AdminLinks />
    </template>
    <template v-else>
      <SiteLinks :isDesktop="false" :isDrawer="true"/>
    </template>
  </v-navigation-drawer>
</template>

<script>
import AdminLinks from './AdminLinks.vue';
import SiteLinks from './SiteLinks.vue';

export default {
  components: {
    AdminLinks,
    SiteLinks,
  },
  props: {
    isAdminView: Boolean,
    drawer: Boolean,
  },
  computed: {
    shouldDisplayDrawer() {
      // Solo muestra el drawer en la vista de Admin o cuando se usa en mobile
      return this.isAdminView || this.drawer;
    }
  },
  methods: {
    updateDrawer(value) {
      this.$emit('update:drawer', value);
    },
  },
};
</script>

<style scoped>
.v-navigation-drawer {
  max-width: 300px;
}
</style>
