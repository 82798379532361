// src/store/modules/display.js

const state = {
    isXs: false,
    isSm: false,
    isMd: false,
    isLg: false,
    isXl: false,
  };
  
  const mutations = {
    SET_BREAKPOINT(state, { breakpoint, value }) {
      state[breakpoint] = value;
    },
  };
  
  const actions = {
    updateBreakpoint({ commit }, displayName) {
      const breakpoints = ['isXs', 'isSm', 'isMd', 'isLg', 'isXl'];
      breakpoints.forEach(breakpoint => {
        commit('SET_BREAKPOINT', {
          breakpoint,
          value: displayName === breakpoint.replace('is', '').toLowerCase(),
        });
      });
    },
  };
  
  const getters = {
    isXs: state => state.isXs,
    isSm: state => state.isSm,
    isMd: state => state.isMd,
    isLg: state => state.isLg,
    isXl: state => state.isXl,
    isMobile: state => state.isXs || state.isSm, // Para compatibilidad con mobile
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
  