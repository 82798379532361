<template>
  <div class="header" id="home-section">
    <video autoplay loop muted playsinline :class="videoClass">
      <source src="/videos/ftAgency.mp4" type="video/mp4" />
    </video>
    <div :class="overlayClass"></div>
    <div class="header-content">
      <v-container class="text-container" fill-height>
        <v-row align="center" justify="center">
          <v-col cols="12" class="text-center">
            <h1 class="headline white--text">{{ $t('header.welcome') }}</h1>
            <p class="subtitle white--text">{{ $t('header.subtitle') }}</p>
            
            <!-- Botón de Contáctanos actualizado -->
            <v-btn
              class="outlined-contact-button"
              outlined
              @click="goToRoute('contact-section')"
            >
              {{ $t('header.buttonText') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "HeaderComponent",
  data() {
    return {
      people: [
        {
          image: require('@/assets/images/people1.jpg'),
          name: 'Nombre de la persona 1',
          info: 'Información de la persona 1',
        },
        {
          image: require('@/assets/images/people2.jpg'),
          name: 'Nombre de la persona 2',
          info: 'Información de la persona 2',
        },
        {
          image: require('@/assets/images/people3.jpg'),
          name: 'Nombre de la persona 3',
          info: 'Información de la persona 3',
        },
      ],
      model: null,
    };
  },
  computed: {
    ...mapGetters('display', ['isMobile']),
    videoClass() {
      return this.isMobile ? 'header-video-mobile' : 'header-video-desktop';
    },
    overlayClass() {
      return this.isMobile ? 'overlay-mobile' : 'overlay-desktop';
    },
  },
  methods: {
    goToRoute(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
};
</script>

<style scoped>
/* Botón de contacto con diseño outlined y bordes personalizados */
.outlined-contact-button {
  color: #C5A57B; /* Color amarillo tirando a naranja */
  font-weight: 600;
  font-size: 1.5rem;
  text-transform: uppercase;
  border-radius: 0;
  padding: 20px 30px 40px 30px;
  margin-top: 20px;
  border: 2px solid #C5A57B; /* Borde con 3px de grosor */
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.outlined-contact-button:hover {
  background-color: rgba(255, 152, 0, 0.1); /* Fondo semi-transparente en hover */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

/* Estilo del botón para versión móvil */
@media (max-width: 600px) {
  .outlined-contact-button {
    font-size: 1rem; /* Tamaño de fuente más pequeño */
    padding: 20px 20px 30px 20px; /* Ajusta el padding para que el botón sea más pequeño */
    border: 2px solid #C5A57B; /* Mantiene el borde en 2px */
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

/* Clases para desktop */
.header-video-desktop {
  width: 100%;
  height: 900px;
  object-fit: cover;
}

.overlay-desktop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1200px;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Clases para mobile */
.header-video-mobile {
  width: 100%;
  height: 900px; /* Ajusta la altura para mobile */
  object-fit: cover;  
}

.overlay-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000px; /* Ajusta la altura para mobile */
  background-color: rgba(0, 0, 0, 0.5);
}

.header-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-container {
  text-align: center;
}

.headline {
  font-size: 3rem;
  font-weight: bold;
}

.subtitle {
  font-size: 1.5rem;
}

.cards-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -160px !important;
  z-index: 3;
}

.cards-container-mobile {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -106px;
  z-index: 3;
}

.cards-row {
  width: 90%;
}

.card-col {
  padding: 0 16px;
}

.info-card {
  position: relative;
  overflow: hidden;
  margin: 0 16px;
  cursor: pointer;
}

.info-card-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.info-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.info-card:hover .info-overlay {
  opacity: 1;
}

.info-card:hover .info-card-image {
  transform: scale(1.05);
}

/* Estilo para las tarjetas en vista móvil */
.info-card-mobile {
  height: 200px;
  width: 300px;
  max-width: 100%;
}

.info-card-image-mobile {
  object-fit: cover;
  width: 100%;
  padding: 0;
}

.info-text-mobile {
  height: 40%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}

.v-slide-group-item {
  flex: 0 0 100%;
}
</style>
