<template>
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn icon v-bind="props">
          <v-icon>mdi-translate</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="changeLanguage('en')">
          <v-row align="center">
            <v-col cols="auto">
              <v-img
                src="@/assets/images/flags/Flag_of_the_United_Kingdom.svg"
                alt="English"
                width="24"
                height="24"
                cover
                rounded="sm"
              />
            </v-col>
            <v-col>
              <v-list-item-title class="ml-2">{{ $t('language.english') }}</v-list-item-title>
            </v-col>
          </v-row>
        </v-list-item>
        <v-list-item @click="changeLanguage('es')">
          <v-row align="center">
            <v-col cols="auto">
              <v-img
                src="@/assets/images/flags/Flag_of_Spain.svg"
                alt="Español"
                width="24"
                height="24"
                cover
                rounded="sm"
              />
            </v-col>
            <v-col>
              <v-list-item-title class="ml-2">{{ $t('language.spanish') }}</v-list-item-title>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-menu>
  </template>
  
  <script>
  export default {
    methods: {
      changeLanguage(lang) {
        this.$i18n.locale = lang;
      }
    }
  };
  </script>
  