<template>
    <v-dialog
      :model-value="dialog"
      @update:model-value="$emit('update:modelValue', $event)" 
      :fullscreen="$vuetify.display.mobile"
      max-width="600"  
      scrollable
    >
      <v-card :class="cardClass">
        <v-toolbar :class="toolbarClass">
          <v-btn icon="mdi-close" @click="closeDialog" class="white--text"></v-btn>
          <v-toolbar-title>{{ playerOrStaff.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
  
        <v-card-text>
          <!-- Imagen del jugador o persona en un círculo -->
          <div class="d-flex justify-center">
            <div class="profile-image-container">
              <v-img
                :src="playerOrStaff.image"
                alt="Player or Staff Image"
                class="profile-image"
                cover
              ></v-img>
            </div>
          </div>
  
          <!-- Tabs para cambiar entre idiomas -->
            <div class="d-flex justify-center mt-3">
                <v-tabs v-model="activeTab" bg-color="primary">
                    <v-tab @click="changeLanguage('es')">
                    <v-img
                        src="@/assets/images/flags/Flag_of_Spain.svg"
                        alt="Español"
                        class="flag-icon"
                    />
                    </v-tab>
                    <v-tab @click="changeLanguage('en')">
                    <v-img
                        src="@/assets/images/flags/Flag_of_the_United_Kingdom.svg"
                        alt="Inglés"
                        class="flag-icon"
                    />
                    </v-tab>
                </v-tabs>
            </div>
          <!-- Contenido dependiendo del idioma seleccionado -->
          <v-container>
            <!-- Información de jugador solo si es un jugador -->
            <v-row class="player-info mt-6 d-flex justify-center" v-if="playerOrStaff.isPlayer">
              <v-col cols="6">
                <strong>{{ $t('players.country') }}:</strong><br>
                <v-img :src="`https://flagcdn.com/h20/${playerOrStaff.countryCode}.png`" alt="Player Nationality" class="player-flag"></v-img><br>
                <strong>{{ $t('players.position') }}</strong><br> {{ playerOrStaff.position }}
              </v-col>
              <v-col cols="6" class="pl-2">
                <strong>{{ $t('players.age') }}:</strong><br> {{ playerOrStaff.age }}<br><br>
                <strong>{{ $t('players.club') }}</strong><br> {{ playerOrStaff.club }}
              </v-col>
            </v-row>
  
            <!-- Sección de biografía -->
            <v-row class="bio-section mt-4">
              <v-col cols="12">
                <!-- Mostrar la biografía solo si existe -->
                <p v-if="playerOrStaff.bio">{{ playerOrStaff.bio[activeLanguage] }}</p>
                <p v-else>{{ $t('players.bio') }}</p> <!-- Bio genérica si no está definida -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
  
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="closeDialog">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'PeopleDialog',
    props: {
      modelValue: {
        type: Boolean,
        required: true,
      },
      playerOrStaff: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        activeTab: this.$i18n.locale === 'es' ? 0 : 1, // La tab activa depende del idioma actual
        activeLanguage: this.$i18n.locale // Idioma actualmente seleccionado
      };
    },
    watch: {
      '$i18n.locale'(newLocale) {
        this.activeTab = newLocale === 'es' ? 0 : 1;
        this.activeLanguage = newLocale;
      }
    },
    computed: {
      dialog: {
        get() {
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
        }
      },
      cardClass() {
        return this.$vuetify.theme.dark ? 'bg-primary textPrimary' : 'bg-primary textPrimary';
      },
      toolbarClass() {
        return 'bg-secondary textPrimary';
      }
    },
    methods: {
      closeDialog() {
        this.$emit('update:modelValue', false);
      },
      changeLanguage(language) {
        this.$i18n.locale = language;
        this.activeLanguage = language;
      }
    }
  };
  </script>
  
  <style scoped>
  .profile-image-container {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 2px solid var(--v-theme-textPrimary);
  }
  
  .profile-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .player-info {
    font-size: 0.9rem;
    color: #333;
    text-align: left;
    background-color: #ffffff;
  }
  
  .player-flag {
    width: 24px;
    height: 16px;
    margin-top: 8px;
    display: block;
  }
  
  .flag-icon {
    width: 40px;
    height: 25px;
    margin: 8px;
  }
  
  .v-toolbar {
    background-color: var(--v-theme-secondary);
    color: var(--v-theme-textPrimary);
  }
  </style>
  