<template>
    <v-list>
      <v-list-item @click="goToRoute('AdminDashboard')" link>
        <v-list-item-content>
          <v-list-item-title>{{ $t('adminLinks.dashboard') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
  
      <v-list-item @click="goToRoute('AdminCalendar')" link>
        <v-list-item-content>
          <v-list-item-title>{{ $t('adminLinks.calendar') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </template>
  
  <script>
  export default {
    methods: {
      goToRoute(route) {
        this.$router.push({ name: route });
      },
    },
  };
  </script>
  