<template>
  <div class="">
    <v-container class="PlayersComponent" :style="{ height: computedHeight }" id="players-section">
      <v-row class="players-title-row" justify="center" align="center">
        <v-col cols="4" class="d-none d-sm-flex">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" xs="12" lg="4" class="text-center">
          <h2 class="players-title">{{ $t('players.sectionTitle') }}</h2>
          <p class="players-subtitle">{{ $t('players.sectionSubtitle') }}</p>
        </v-col>
        <v-col cols="4" class="d-none d-sm-flex">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row class="players-slide-group-row">
        <v-col class="slide-group-container">
          <v-slide-group center-active>
            <v-slide-group-item v-for="player in players" :key="player.id">
              <PlayerCard :player="player" @open-dialog="openPlayerDialog" />
            </v-slide-group-item>
          </v-slide-group>
        </v-col>
      </v-row>
    </v-container>

    <!-- Asegúrate de que el diálogo esté bien controlado -->
    <PeopleDialog
      v-if="selectedPlayer"
      :playerOrStaff="selectedPlayer"
      :isPlayer="true"
      v-model="dialog"
    />
  </div>
</template>

<script>
import PeopleDialog from "../base/PeopleDialog.vue";
import PlayerCard from "./PlayerCard.vue";
import { mapGetters } from "vuex";

export default {
  name: "PlayersComponent",
  components: {
    PlayerCard,
    PeopleDialog
  },
  computed: {
    ...mapGetters('display', ['isMobile']),
    computedHeight() {
      return this.isMobile ? '650px' : '800px';
    },
  },
  data() {
    return {
      players: [
          {
            id: 1,
            name: "Lionel Messi",
            position: "Delantero",
            age: 36,
            club: "Inter Miami",
            image: require('@/assets/images/player1.webp'),
            countryCode: "ar",
          },
          {
            id: 2,
            name: "Neymar Jr.",
            position: "Delantero",
            age: 31,
            club: "Al-Hilal",
            image: require('@/assets/images/player2.webp'),
            countryCode: "br",
          },
          {
            id: 3,
            name: "Paul Pogba",
            position: "Mediocentro",
            age: 30,
            club: "Juventus",
            image: require('@/assets/images/player3.webp'),
            countryCode: "fr",
          },
          {
            id: 4,
            name: "Sergio Ramos",
            position: "Defensor",
            age: 37,
            club: "Sevilla FC",
            image: require('@/assets/images/player2.webp'),
            countryCode: "es",
          },
          {
            id: 4,
            name: "Sergio Ramos",
            position: "Defensor",
            age: 37,
            club: "Sevilla FC",
            image: require('@/assets/images/player1.webp'),
            countryCode: "es",
          },
          {
            id: 4,
            name: "Sergio Ramos",
            position: "Defensor",
            age: 37,
            club: "Sevilla FC",
            image: require('@/assets/images/player3.webp'),
            countryCode: "es",
          },
        ],
      dialog: false, // Controla la visibilidad del modal
      selectedPlayer: null, // Jugador seleccionado para el modal
    };
  },
  methods: {
    openPlayerDialog(player) {
      this.selectedPlayer = player; // Asigna el jugador seleccionado
      this.dialog = true; // Abre el modal
    },
  },
};
</script>

<style scoped>
.playerSection {
  position: relative;
  background-image: url('@/assets/images/text1.jpg');
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.playerSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
}

.PlayersComponent {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.players-title-row {
  align-items: center;
  justify-content: center;
}

.players-title {
  font-family: 'Tenor Sans', serif;
  color: #C5A57B;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}

.players-slide-group-row {
  flex-grow: 1; /* Ocupa el espacio restante del contenedor */
  display: flex;
  align-items: center; /* Alinea verticalmente al centro */
  justify-content: center; /* Alinea horizontalmente al centro */
}

.slide-group-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.players-subtitle {
  font-family: 'Josefin Sans', sans-serif; /* Puedes cambiar la fuente si es necesario */
  color: #FFFFFF;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .players-title {
    font-size: 1.8rem;
  }
  .players-subtitle {
    font-size: 1rem;
  }
}
</style>
