<template>
  <v-parallax src="@/assets/images/estadio1.jpg" class="team-section">
    <!-- Título de la sección -->
    <v-container class="title-container">
      <v-row class="team-title-row" justify="center" align="center">
        <v-col cols="4" class="d-none d-sm-flex">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" xs="12" lg="4" class="text-center">
          <h2 class="team-title">{{ $t("team.title") }}</h2>
          <h3 class="team-subtitle">{{ $t("team.subtitle") }}</h3>
        </v-col>
        <v-col cols="4" class="d-none d-sm-flex">
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-container>

    <!-- Vista de Escritorio -->
    <v-container v-if="!isMobile" class="d-flex justify-center align-center fill-height">
      <v-row class="fill-height align-center">
        <v-col cols="12" md="4" v-for="(person, index) in people" :key="index" class="team-card-container scroll-reveal">
          <v-hover v-slot:default="{ isHovering, props }">
            <v-card v-bind="props" class="team-card" :elevation="isHovering ? 10 : 2" @click="openDialog(person)">
              <v-img :src="person.image" class="team-image"></v-img>
              <div v-if="isHovering" class="overlay">
                <div class="overlay-text">
                  <h3>{{ person.name }}</h3>
                  <p>{{ person.role }}</p>
                </div>
              </div>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>

    <!-- Vista Móvil -->
    <v-container v-else class="d-flex justify-center align-center">
      <v-slide-group center-active>
        <v-slide-group-item v-for="(person, index) in people" :key="index">
          <v-card rounded="4" class="mr-4 mobile-team-card" elevation="10" @click="openDialog(person)">
            <v-img width="300" height="500" cover :src="person.image"></v-img>
          </v-card>
        </v-slide-group-item>
      </v-slide-group>
    </v-container>

    <!-- Diálogo de la persona -->
    <people-dialog :model-value="dialog" :player-or-staff="selectedPerson" :is-player="false" @update:model-value="dialog = $event" />
  </v-parallax>
</template>

<script>
import PeopleDialog from '@/components/base/PeopleDialog.vue';
import { mapGetters } from 'vuex';

export default {
  components: { PeopleDialog },
  computed: {
    ...mapGetters("display", ["isMobile"]),
  },
  data() {
    return {
      people: [
      {
        name: "Persona 1",
        role: "Agente de Representación",
        image: require("@/assets/images/people1.jpg"),
        bio: {
          en: "Person 1 has been working in football player representation for many years...",
          es: "Persona 1 ha trabajado en la representación de futbolistas durante muchos años..."
        }
      },
      {
        name: "Persona 2",
        role: "Agente de Representación",
        image: require("@/assets/images/people2.jpg"),
        bio: {
          en: "Person 2 specializes in creating strategies for young footballers...",
          es: "Persona 2 se especializa en la creación de estrategias para futbolistas jóvenes..."
        }
      },
      {
        name: "Persona 3",
        role: "Agente de Representación",
        image: require("@/assets/images/people3.jpg"),
        bio: {
          en: "Person 3 brings extensive experience in talent management...",
          es: "Persona 3 aporta una vasta experiencia en la gestión de talento..."
        }
      }
    ],
    players: [
      {
        name: "Jugador 1",
        isPlayer: true,
        position: "Delantero",
        age: 25,
        club: "Club A",
        countryCode: "ar",
        bio: {
          en: "This player has shown great dedication...",
          es: "Este jugador ha demostrado una gran dedicación..."
        }
      }
    ],
      dialog: false,
      selectedPerson: null
    };
  },
  methods: {
    openDialog(person) {
      this.selectedPerson = person;
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
.team-section {
  position: relative;
  height:110vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-container {
  position: absolute;
  top: 20px;
  width: 100%;
}

.team-title-row {
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.team-title {
  font-family: "Tenor Sans", serif;
  color: #c5a57b;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
}

@media (max-width: 600px) {
  .team-title {
    font-size: 1.8rem;
  }
}

.team-card-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.team-card {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}
@media (min-width: 600px) {
  .scroll-reveal {
    transform: translateY(50px);
    opacity: 0;
    transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
  }

  .scroll-reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
}

.team-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.overlay-text {
  text-align: center;
}

.overlay h3 {
  margin: 0;
  font-size: 1.5rem;
}

.overlay p {
  margin: 5px 0 0;
  font-size: 1rem;
}

/* Estilos para la vista móvil */
.mobile-slide-group {
  overflow-x: auto;
  padding: 10px 0;
}

.mobile-team-card {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Agrega sombra a la tarjeta */
}

.mobile-team-image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.mobile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.mobile-overlay-text {
  text-align: center;
}

.mobile-overlay h3 {
  margin: 0;
  font-size: 1.5rem;
}

.mobile-overlay p {
  margin: 5px 0 0;
  font-size: 1rem;
}
.mobile-team-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
