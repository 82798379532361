<template>
  <v-app-bar
    :color="isMobile || isScrolled ? 'primary' : 'transparent'"
    :elevation="isMobile || isScrolled ? 4 : 0"
    :height="appBarHeight"
    app
    dark
    fixed
  >
    <!-- Logo a la izquierda -->
    <v-toolbar-title @click="goToRoute('Home')" style="cursor: pointer;">
      <v-img :src="logo" alt="iabot logo" :height="logoHeight" width="auto"></v-img>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- Mostrar links del sitio solo en vista desktop -->
    <template v-if="!isMobile">
      <SiteLinks :isDesktop="true" />
    </template>

    <!-- Componente para seleccionar idioma -->
    <LanguageSelector />

    <!-- Botón de menú hamburguesa solo en vista mobile -->
    <v-app-bar-nav-icon v-if="isMobile" @click="toggleDrawer"></v-app-bar-nav-icon>
  </v-app-bar>
</template>


<script>
import SiteLinks from './SiteLinks.vue';
import LanguageSelector from './LanguageSelector.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    SiteLinks,
    LanguageSelector
  },
  props: {
    isAdminView: Boolean,
    isScrolled: Boolean,
    drawer: Boolean,
    isMobile: Boolean // Recibe la prop isMobile de App.vue
  },
  data() {
    return {
      logo: require('@/assets/images/Logos-Support-2.png'),
    };
  },
  computed: {
  ...mapGetters('display', ['isMobile']),
  appBarHeight() {
    return this.isMobile ? '60' : '80';  // Ajusta los valores según tus necesidades
  },
  logoHeight() {
    return this.isMobile ? 60 : 70;  // Ajusta estos valores según tus necesidades
  }
},
  methods: {
    goToRoute(route) {
      this.$router.push({ name: route });
    },
    toggleDrawer() {
      this.$emit('toggle-drawer');
    },
  }
};
</script>

<style scoped>
.v-toolbar-title {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
