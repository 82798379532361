<template>
  <v-app>
    <AppBar
      :isAdminView="isAdminView"
      :isScrolled="isScrolled"
      :drawer="drawer"
      :isMobile="isMobile"
      :breakpoint="{ isXs, isSm, isMd, isLg, isXl }"
      @toggle-drawer="toggleDrawer"
    />
    <AppDrawer
      :isAdminView="isAdminView"
      :drawer="drawer"
      :breakpoint="{ isXs, isSm, isMd, isLg, isXl }"
      @update:drawer="drawer = $event"
    />
    <v-main class="main-content">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { logout as userLogout, refreshToken } from '@/services/user.service';
import AppBar from './components/base/AppBar.vue';
import AppDrawer from './components/base/AppDrawer.vue';
import { fetchTienda } from '@/services/tienda.service';

export default {
  name: 'App',
  components: {
    AppBar,
    AppDrawer,
  },
  data() {
    return {
      drawer: false,
      refreshTokenInterval: null,
      isScrolled: false,
    };
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated', 'username', 'email', 'id']),
    ...mapGetters('tienda', ['tienda']),
    ...mapGetters('display', ['isXs', 'isSm', 'isMd', 'isLg', 'isXl', 'isMobile']),
    isAdminView() {
      return this.$route.matched.some(record => record.meta.requiresAuth);
    },
  },
  methods: {
    ...mapActions('display', ['updateBreakpoint']),
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    handleScroll() {
      if (!this.isMobile) {
        this.isScrolled = window.scrollY > window.innerHeight * 0.2;
      }
    },
    async handleLogout() {
      await userLogout();
      this.deauthenticateUser();
      this.$router.push({ name: 'Home' });
      this.clearRefreshTokenInterval();
    },
    async loadTienda() {
      try {
        const tiendaData = await fetchTienda();
        this.setTienda(tiendaData);
      } catch (error) {
        console.error('Error al obtener los datos de la tienda:', error);
      }
    },
    async initializeUser() {
      const accessToken = localStorage.getItem('access_token');
      if (accessToken) {
        try {
          await refreshToken();
          const userData = {
            username: localStorage.getItem('username'),
            email: localStorage.getItem('email'),
            id: localStorage.getItem('id')
          };
          this.authenticateUser(userData);
          this.startRefreshTokenInterval();
          await this.loadTienda();
        } catch (error) {
          this.deauthenticateUser();
        }
      }
    },
    startRefreshTokenInterval() {
      this.refreshTokenInterval = setInterval(async () => {
        try {
          await refreshToken();
        } catch (error) {
          this.deauthenticateUser();
        }
      }, 15 * 60 * 1000);
    },
    clearRefreshTokenInterval() {
      if (this.refreshTokenInterval) {
        clearInterval(this.refreshTokenInterval);
        this.refreshTokenInterval = null;
      }
    }
  },
  watch: {
    '$vuetify.display.name': {
      immediate: true,
      handler(newVal) {
        this.updateBreakpoint(newVal);
      },
    },
  },
  mounted() {
    this.updateBreakpoint(this.$vuetify.display.name);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style>
/* Establecer Josefin Sans como la fuente predeterminada */
body, div, p, span, input, button {
  font-family: 'Josefin Sans', sans-serif;
}

/* Establecer Tenor Sans para los encabezados */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Tenor Sans', serif;
}
.v-navigation-drawer {
  max-width: 300px;
}

.main-content {
  position: relative;
  z-index: 1; 
  min-height: 2000px;
}
</style>