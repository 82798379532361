<template>
  <v-list
    :class="[
      'transparent-list',
      isDesktop ? 'horizontal-list' : 'vertical-list',
      isDrawer ? 'drawer' : ''
    ]"
  >
    <v-list-item @click="goToRoute('home-section')" link>
      <v-list-item-content>
        <v-list-item-title :class="['link-text', isDrawer ? 'drawer-text' : '']">
          {{ $t('homeLinks.home') }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item @click="goToRoute('about-us')" link>
      <v-list-item-content>
        <v-list-item-title :class="['link-text', isDrawer ? 'drawer-text' : '']">
          {{ $t('homeLinks.about') }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item @click="goToRoute('players-section')" link>
      <v-list-item-content>
        <v-list-item-title :class="['link-text', isDrawer ? 'drawer-text' : '']">
          {{ $t('homeLinks.players') }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item @click="goToRoute('contact-section')" link>
      <v-list-item-content>
        <v-list-item-title :class="['link-text', isDrawer ? 'drawer-text' : '']">
          {{ $t('homeLinks.contact') }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    isDesktop: {
      type: Boolean,
      default: false,
    },
    isDrawer: {
      type: Boolean,
      default: false, // Por defecto, está en la AppBar
    },
  },
  methods: {
    goToRoute(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>

<style scoped>
.transparent-list {
  background-color: transparent !important;
}

.horizontal-list {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.vertical-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link-text {
  padding: 0;
  text-transform: none;
  font-weight: normal;
  color: rgb(var(--v-theme-on-primary)); /* Blanco */
}

.v-list-item:hover .link-text {
  color: rgb(var(--v-theme-secondary)); /* Cambia a dorado en hover */
}

.drawer-text {
  color: rgb(var(--v-theme-on-surface)); /* Texto oscuro sobre fondo claro */
}

.v-list-item.drawer:hover .drawer-text {
  color: rgb(var(--v-theme-primary)); /* Cambia a azul oscuro en hover */
}

.v-list-item {
  padding: 0 8px;
}
</style>
