<template>
  <v-row no-gutters class="contact-section" id="contact-section">
    <!-- Columna izquierda con datos de contacto -->
    <v-col cols="12" md="4" class="contact-left">
      <div class="contact-info-card scroll-reveal">
        <h3>{{ $t("contact.title") }}</h3>
        <div class="social-buttons d-flex justify-center">
          <v-btn icon color="green" class="mr-4" :href="whatsappLink" target="_blank">
            <v-icon>mdi-whatsapp</v-icon>
          </v-btn>
          <v-btn icon color="pink" :href="instagramLink" target="_blank">
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
        </div>
        <p>{{ $t("contact.phone") }}: +123 456 789</p>
        <p>{{ $t("contact.email") }}: info@example.com</p>
        <p>{{ $t("contact.address") }}: Calle Falsa 123, Ciudad, País</p>
      </div>
    </v-col>

    <!-- Columna derecha con el formulario -->
    <v-col cols="12" md="8" class="contact-right">
      <v-container class="contact-form-container d-flex justify-center align-center">
        <div>
          <div class="form-info-card">
            <h3>{{ $t("contact.formTitle") }}</h3>
            <p>{{ $t("contact.formSubtitle") }}</p>
          </div>
          <v-form>
            <v-text-field
              :label="$t('contact.name')"
              required
              class="contact-field"
              outlined
            ></v-text-field>
            <v-text-field
              :label="$t('contact.email')"
              required
              class="contact-field"
              outlined
            ></v-text-field>
            <v-textarea
              :label="$t('contact.message')"
              required
              class="contact-field"
              outlined
            ></v-textarea>
            <v-btn color="primary" class="contact-btn">{{ $t("contact.send") }}</v-btn>
          </v-form>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "ContactComponent",
  computed: {
    ...mapGetters('display', ['isMobile']),
  },
  data() {
    return {
      whatsappLink: "https://wa.me/1234567890",
      instagramLink: "https://instagram.com/supportfootballagency",
    };
  },
  mounted() {
    // Añadir animación en scroll
    document.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const elements = document.querySelectorAll(".scroll-reveal");
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
        if (isVisible) {
          element.classList.add("active");
        } else {
          element.classList.remove("active");
        }
      });
    },
  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.contact-section {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
}

.contact-left,
.contact-right {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-left {
  min-height: 100vh;
  background-image: url('@/assets/images/jugador1.jpg');
  background-size: cover;
  background-position: center;
}

.contact-right {
  min-height: 100vh;
  background-image: url('@/assets/images/campo2_opa_black_40.png');
  background-size: cover;
  background-position: center;
}

.contact-info-card {
  background-color: rgba(0, 0, 0, 0.35);
  padding: 20px;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(50px);
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.contact-info-card.active {
  transform: translateY(0);
  opacity: 1;
}

.contact-form-container {
  padding: 40px;
  width: 100%; /* Para que ocupe el ancho completo pero centrado */
  max-width: 600px; /* Limitar el tamaño del formulario */
}

.form-info-card {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact-field {
  margin-bottom: 20px;
}

.contact-btn {
  width: 100%;
}

.social-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

@media (max-width: 960px) {
  .contact-left {
    min-height: 100vh;
  }

  .contact-right {
    min-height: 100vh;
  }
}
</style>
