import { createStore } from 'vuex';
import user from './modules/user';
import tienda from './modules/tienda';
import display from './modules/display';

export default createStore({
  modules: {
    user,
    tienda,
    display
  }
});
