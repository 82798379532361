<template>
   <div class="about-us-section" id="about-us">
    <v-container>
      <v-row class="about-us-title-row" justify="center" align="center">
        <v-col cols="4" class="d-none d-sm-flex">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" sm="12" lg="4" class="text-center">
          <h2 class="about-us-title">{{ $t("about.title") }}</h2>
        </v-col>
        <v-col cols="4" class="d-none d-sm-flex">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row class="about-us-content d-flex justify-center">
        <v-col cols="12" lg="5">
          <v-img
            :src="require('@/assets/images/Logo-SUPPORT-1-transpatenr.png')"
            alt="Sobre nosotros"
            class="about-us-logo scroll-reveal"
            width="100%"
            height="400"
          ></v-img>
        </v-col>
        <v-col cols="12" lg="5">
          <v-card class="about-us-card scroll-reveal-text">
            <v-card-title class="card-title">{{
              $t("about.missionTitle")
            }}</v-card-title>
            <v-card-text class="card-content">{{
              $t("about.missionContent")
            }}</v-card-text>
            <v-card-actions class="justify-center">
              <v-btn variant="outlined" color="secondary" style="z-index: 999">
                {{ $t("about.buttonText") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "aboutUsComponente",

  mounted() {
    document.addEventListener("scroll", function () {
      const elements = document.querySelectorAll(
        ".scroll-reveal, .scroll-reveal-text"
      );

      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;

        if (isVisible) {
          element.classList.add("active");
        } else {
          element.classList.remove("active");
        }
      });
    });
  },
};
</script>

<style scoped>
.about-us-section {
  padding: 60px 0;
  background: url("@/assets/images/elegant1.jpg") no-repeat center center;
  background-size: cover;
  position: relative;
  min-height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(29, 42, 57, 0.7);
}

.about-us-content {
  align-items: center;
  padding-top: 40px; /* Ajuste para vista escritorio */
}

.v-container {
  position: relative;
}

.about-us-title-row {
  align-items: center;
  justify-content: center;
  margin-top: -160px; /* Mueve toda la fila hacia arriba */
  margin-bottom: 40px; /* Ajusta según sea necesario */
}

.about-us-title {
  font-family: "Tenor Sans", serif;
  color: #c5a57b;
  font-size: 2rem;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
}

.about-us-content {
  align-items: center;
}

.about-us-logo {
  max-width: 100%;
  transform: translateY(50px);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
}

.about-us-card {
  background-image: url("@/assets/images/campo2.png");
  background-size: cover;
  background-repeat: no-repeat;

  color: white;
  padding: 30px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  position: relative;
  min-height: 400px !important;
}

.about-us-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.v-card-title {
  font-family: "Tenor Sans", serif;
  font-size: 2rem;
  font-weight: 600;
  color: white;
  text-align: center;
  position: relative;
  z-index: 2;
  margin-bottom: 20px;
}

.v-card-text {
  font-family: "Josefin Sans", sans-serif;
  font-size: 1rem;
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
}

.v-card-actions {
  margin-top: 20px;
}

.custom-button {
  border-color: #c5a57b;
  color: #c5a57b;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 0;
  min-width: 150px;
}

.custom-button:hover {
  background-color: rgba(
    197,
    165,
    123,
    0.1
  ); /* Efecto hover con un poco de color */
}

.scroll-reveal {
  transform: translateY(50px);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
}

.scroll-reveal.active {
  transform: translateY(0);
  opacity: 1;
}

.scroll-reveal-text {
  transform: translateX(50px);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
}

.scroll-reveal-text.active {
  transform: translateX(0);
  opacity: 1;
}

@media (max-width: 600px) {
  .scroll-reveal-text {
    transform: translateY(50px);
  }

  .about-us-content {
    padding-top: 20px; /* Ajuste para vista móvil */
  }

  .scroll-reveal-text.active {
    transform: translateY(0);
    opacity: 1;
  }

  .about-us-title {
    font-size: 1.8rem;
  }

  .about-us-title-row {
    margin-top: -20px; /* Ajusta para vista móvil */
    margin-bottom: -50px; /* Reduce la distancia entre el título y el logo */
  }
}
</style>
