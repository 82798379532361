// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify';
import * as directives from 'vuetify/directives';
import { VCalendar } from 'vuetify/labs/VCalendar';

const customTheme = {
  dark: false,
  colors: {
    background: '#1D2A39',  // Azul oscuro del fondo
    primary: '#1D2A39',     // Azul oscuro principal (mismo que background)
    secondary: '#C5A57B',   // Dorado/Beige para acentos y elementos secundarios
    accent: '#C5A57B',      // Dorado/Beige para elementos interactivos (mismo que secondary)
    error: '#FF5252',       // Rojo para errores
    info: '#2196F3',        // Azul para información
    success: '#4CAF50',     // Verde para éxito
    warning: '#FB8C00',     // Naranja para advertencias
    textPrimary: '#FFFFFF', // Blanco para el texto principal
    textSecondary: '#C5A57B', // Dorado/Beige para el texto secundario (mismo que secondary)
    border: '#D1D5DB',      // Gris suave para bordes
    surface: '#ffffff',     // Blanco para el fondo de componentes como AppDrawer
    onSurface: '#000000',   // Negro para el texto sobre el fondo 'surface'
  },
  variables: {
    fontFamily: 'Josefin Sans, sans-serif',
    fontFamilyHeadline: 'Tenor Sans, serif',
  },
};

export default createVuetify({
  components: {
    VCalendar,
  },
  directives,
  icons: {
    defaultSet: 'mdi',
  },
  theme: {
    defaultTheme: 'customTheme',
    themes: {
      customTheme,
    },
  },
});
