<template>
    <v-card class="player-card ml-4" @click="openDialog">
      <v-img :src="player.image" alt="Player Image" class="player-image"></v-img>
      <v-card-title class="player-name">{{ player.name }}</v-card-title>
      <v-card-text class="player-info">
        <v-row class="d-flex aling-center">
          <v-col cols="6" class="pa-0 ma-0">
            <strong>{{ $t('players.country') }}:</strong><br>
            <v-img :src="`https://flagcdn.com/h20/${player.countryCode}.png`" alt="Player Nationality" class="player-flag"></v-img><br>
            <strong>{{ $t('players.position') }}</strong><br> {{ player.position }}
          </v-col>
          <v-col cols="6" class="pl-2 pt-0 ma-0 ">
            <strong>{{ $t('players.age') }}:</strong><br> {{ player.age }}<br><br>
            <strong>{{ $t('players.club') }}</strong><br> {{ player.club }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  export default {
    name: 'PlayerCard',
    props: {
      player: {
        type: Object,
        required: true
      }
    },
    methods:{
      openDialog() {
        console.log("open-dialog")
        this.$emit('open-dialog', this.player);
      } 
    },
  }
  </script>
  
  <style scoped>
  .player-card {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    overflow: hidden;
    text-align: center;
    padding: 16px;
    position: relative;
    width: 280px !important;
  }
  
  .player-image {
    width: 100%;
    padding:0px 0px 0px 0px ;
    object-fit: cover;
    margin-bottom: 16px;
  }
  
  .player-name {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 12px;
  }
  
  .player-info {
    font-size: 0.9rem;
    color: #333;
    text-align: left;
  }
  
  .player-flag {
    width: 24px;
    height: 16px;
    margin-top: 8px;
    display: block;
  }
  </style>
  