<template>
  <v-footer class="footer">
    <v-container>
      <v-row>
        <!-- Navegación -->
        <v-col cols="12" md="4">
          <h3>{{ $t('footer.navigationTitle') }}</h3>
          <ul class="footer-list">
            <li><a href="#" @click.prevent="goToRoute('home-section')">{{ $t('homeLinks.home') }}</a></li>
            <li><a href="#" @click.prevent="goToRoute('about-us')">{{ $t('homeLinks.about') }}</a></li>
            <li><a href="#" @click.prevent="goToRoute('players-section')">{{ $t('homeLinks.players') }}</a></li>
            <li><a href="#" @click.prevent="goToRoute('contact-section')">{{ $t('homeLinks.contact') }}</a></li>
          </ul>
        </v-col>

        <!-- Información de contacto -->
        <v-col cols="12" md="4">
          <h3>{{ $t('footer.contactTitle') }}</h3>
          <p>{{ $t('contact.phone') }}: +123 456 789</p>
          <p>{{ $t('contact.email') }}: info@example.com</p>
          <p>{{ $t('contact.address') }}: Calle Falsa 123, Ciudad, País</p>
        </v-col>

        <!-- Redes sociales -->
        <v-col cols="12" md="4">
          <h3>{{ $t('footer.socialTitle') }}</h3>
          <v-btn icon color="white" :href="whatsappLink" target="_blank">
            <v-icon>mdi-whatsapp</v-icon>
          </v-btn>
          <v-btn icon color="white" :href="instagramLink" target="_blank">
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>

      <!-- Derechos reservados -->
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <p>{{ $t('footer.companyName') }} - {{ new Date().getFullYear() }} {{ $t('footer.rights') }}</p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>


<script>
export default {
  computed: {
    whatsappLink() {
      return 'https://wa.me/123456789';
    },
    instagramLink() {
      return 'https://instagram.com/supportfootballagency';
    }
  },
  methods:{
    goToRoute(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
};
</script>

<style scoped>
.footer {
  background-color: rgb(var(--v-theme-primary));
  color: white;
  padding: 40px 0;
  margin-top: 0;
  position: relative;
}

.footer h3 {
  color: white;
  margin-bottom: 20px;
}

.footer-list {
  list-style-type: none;
  padding: 0;
}

.footer-list li {
  margin-bottom: 10px;
}

.footer-list a {
  color: white;
  text-decoration: none;
  font-family: 'Josefin Sans', sans-serif;
}

.footer-list a:hover {
  text-decoration: underline;
}
</style>
