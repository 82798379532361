<template>
  <div>
    <HeaderComponent/>
    <div :style="aboutUsContainerStyle" class="about-us-container">
      <AboutUsComponente />
    </div>
    <PlayersComponent />
    <TeamSection />
    <ContactComponent />
    <FooterComponent />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AboutUsComponente from "@/components/base/aboutUsComponente.vue";
import HeaderComponent from "@/components/base/HeaderComponent.vue";
import PlayersComponent from "@/components/players/PlayersComponent.vue";
import TeamSection from '@/components/base/TeamSection.vue';
import ContactComponent from '@/components/base/ContactComponent.vue';
import FooterComponent from '@/components/base/FooterComponent.vue';


export default {
  components: {
    HeaderComponent,
    AboutUsComponente,
    PlayersComponent,
    TeamSection,
    ContactComponent,
    FooterComponent
  },
  computed: {
    ...mapGetters('display', ['isMobile']),
    aboutUsContainerStyle() {
      return {
        marginTop: this.isMobile ? '90vh' : '90vh', // Ajusta estos valores según tus necesidades
      };
    }
  }
};
</script>

