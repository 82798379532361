import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // Asegúrate de importar el store
import vuetify from './plugins/vuetify'; // Asegúrate de importar Vuetify
import { loadFonts } from './plugins/webfontloader';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import 'vue-cal/dist/vuecal.css';
/* bootstrap */
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import { createI18n } from 'vue-i18n'; // Importa vue-i18n
import en from './i18n/locales/en.json';
import es from './i18n/locales/es.json';

loadFonts();

// Configura i18n
const i18n = createI18n({
  locale: 'es', // Idioma predeterminado
  fallbackLocale: 'en', // Idioma alternativo si no se encuentra una clave
  messages: {
    en,
    es
  }
});

const app = createApp(App);

app.use(router);
app.use(store); // Asegúrate de usar el store
app.use(vuetify); // Asegúrate de usar Vuetify
app.use(i18n); // Usa i18n en la app
app.use(Toast, {
  position: POSITION.TOP_RIGHT,
});

app.mount('#app');